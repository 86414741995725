import React, { useEffect, useState } from 'react';
import MainLayout from '../_layouts/main';
import { withFirebase } from '../_firebase';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { icons, localization } from '../_helpers/materialTableProperties';
import { convertArrayToCsv } from '../_helpers/csvConverter';
import _ from 'lodash';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Description from '@material-ui/icons/Description';
import { formatFirestoreTimestamp, mapFirestoreTimestampToMoment } from '../_helpers/mappers';
import moment from 'moment';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';

function UsersPage({ firebase }) {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);

        firebase.getUsers()
            .then(users => {
                setUsers(users);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const redirectToStoreData = (rowData) => {
        if(rowData.storeDocumentPath){
            const textArray = rowData.storeDocumentPath.split('/');

            const platformId = textArray[1]
            const storeId = textArray[textArray.length-1]
    
            if(platformId && storeId){
                return window.open(`/platforms/${platformId}/stores?filter=${storeId}`, '_blank')
            }else{
                const errorMessage = 'Não é possível acessar o painel do cliente. Por favor, tente sair do painel de controle da Mobilizei e se logar novamente.';
                alert(errorMessage)
                return
            }
        }else{
            const errorMessage = 'Não é possível acessar o painel do cliente. Por favor, tente sair do painel de controle da Mobilizei e se logar novamente.';
            alert(errorMessage)
            return
        }
        
    }

    const goToStorePanel = (userId) => {
        setLoading(true);
        const errorMessage = 'Não é possível acessar o painel do cliente. Por favor, tente sair do painel de controle da Mobilizei e se logar novamente.';

        mobilizeiStoresService.getStorePanelUrl(userId)
            .then(response => {
                if (response && response.panelUrl) {
                    window.open(response.panelUrl)
                } else {
                    alert(errorMessage);
                }
            })
            .catch(() => alert(errorMessage))
            .finally(() => setLoading(false))
    }

    const getWhatsAppLink = (phoneNumber) => {
        if(!_.isEmpty(phoneNumber)) {
            const nomalizedPhone = phoneNumber.replace(/\D/g,'');
            return <a target="_blank" href={`https://api.whatsapp.com/send?phone=55${nomalizedPhone}`}>{phoneNumber}</a>
        }

        return '-';
    }

    return (
        <MainLayout title={`Usuários`}>
            <MaterialTable
                actions={[
                    rowData => ({
                        icon: () => <DashboardIcon />,
                        tooltip: 'Acessar painel do cliente',
                        onClick: () => goToStorePanel(rowData.id)
                    }),
                    rowData => ({
                        icon: () => <Description />,
                        tooltip: 'Ver dados da loja',
                        onClick: () => redirectToStoreData(rowData),
                        disabled: !rowData.isOnboardingCompleted
                    }),
                ]}
                columns={[
                    {
                        title: 'Data de Cadastro',
                        field: 'createdAt',
                        render: rowData => formatFirestoreTimestamp(rowData['createdAt'], 'DD/MM/YYYY'),
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(a['createdAt']);
                            let date2 = mapFirestoreTimestampToMoment(b['createdAt']);

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Nome',
                        field: 'name',
                        customSort: (a, b) => a['name'].toLowerCase().localeCompare(b['name'].toLowerCase())
                    },
                    { 
                        title: 'Email', 
                        field: 'email' 
                    },
                    { 
                        title: 'WhatsApp', 
                        field: 'phoneNumber',
                        render: rowData => getWhatsAppLink(rowData.phoneNumber)
                    },
                    {
                        title: 'E-commerce',
                        field: 'ecommercePlatformName',
                        render: rowData => rowData.ecommercePlatformName || '-'
                    },
                    { 
                        title: 'Onboarding finalizado?', 
                        field: 'isOnboardingCompleted',
                        customSort: (a, b) => a['isOnboardingCompleted'].localeCompare(b['isOnboardingCompleted']),
                        render: rowData => rowData.isOnboardingCompleted ? 'Sim' : 'Não',
                        lookup: {
                            true: 'Sim',
                            false: 'Não',
                        },
                    }
                ]}
                data={users}
                icons={icons}
                isLoading={loading}
                localization={localization}
                options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                        let usersArray = [];

                        data.forEach(user => {
                            usersArray.push({
                                createdAt: formatFirestoreTimestamp(user['createdAt'], 'DD/MM/YYYY'),
                                name: user.name,
                                email: user.email,
                                phoneNumber: user.phoneNumber,
                                platformName: user.ecommercePlatformName,
                                storeUrl: user.isOnboardingCompleted
                            })
                        });

                        const header = 'Data de Cadastro;Nome;E-mail;WhatsApp;E-commerce;Onboarding finalizado?';
                        convertArrayToCsv(usersArray, header);
                    },
                    filtering: true,
                    pageSize: 10
                }}
                title={''}
            />
        </MainLayout>
    );
}

export default withRouter(withFirebase(UsersPage));