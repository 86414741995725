import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withFirebase } from '../_firebase';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(4),
    width: '100%',
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage(props) {
  const INITIAL_STATE = { email: '', password: '', error: null };

  const classes = useStyles();
  const [formData, setFormData] = useState(INITIAL_STATE);

  const onSubmit = event => {
    const { email, password } = formData;

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setFormData({ ...INITIAL_STATE });
        props.history.push('/');
      })
      .catch(error => {
        setFormData({ error });
      });

    event.preventDefault();
  };
  
  const onChange = event => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src={require('../_assets/img/mobilizei_logo.png').default} />
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={onChange}
            value={formData.email || ''}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onChange}
            value={formData.password || ''}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Entrar
          </Button>

          {formData.error && <p>{formData.error.message}</p>}
        </form>
      </div>
    </Container>
  );
}

export default withRouter(withFirebase(LoginPage));