import React, { useEffect, useState } from 'react';
import MainLayout from '../_layouts/main';
import { useLocation, useParams } from "react-router-dom";
import { withFirebase } from '../_firebase';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { icons, localization } from '../_helpers/materialTableProperties';
import { convertArrayToCsv } from '../_helpers/csvConverter';
import _ from 'lodash';
import AndroidIcon from '@material-ui/icons/AndroidRounded';
import AppleIcon from '@material-ui/icons/Apple';
import DashboardIcon from '@material-ui/icons/Dashboard';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';
import DetailsPanel from './DetailsPanel';
import { formatFirestoreTimestamp, getPublishStatusChip, getStoreStatusChip, mapFirestoreTimestampToMoment } from '../_helpers/mappers';
import moment from 'moment';
import { plans } from '../_constants/plans';

function StoresPage({ firebase }) {
    let { platformId } = useParams();
    let { search } = useLocation();

    const query = new URLSearchParams(search);
    const defaultFilter = query.get('filter');

    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);

    const createdAtFieldName = 'createdAt';
    const nameFieldName = 'name';
    
    useEffect(() => {
        getData();
    }, [platformId]);

    const getData = () => {
        setLoading(true);

        firebase.getStores(platformId)
            .then(stores => {
                setStores(stores);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const goToStorePanel = (userId) => {
        setLoading(true);
        const errorMessage = 'Não é possível acessar o painel do cliente. Por favor, tente sair do painel de controle da Mobilizei e se logar novamente.';

        mobilizeiStoresService.getStorePanelUrl(userId)
            .then(response => {
                if (response && response.panelUrl) {
                    window.open(response.panelUrl)
                } else {
                    alert(errorMessage);
                }
            })
            .catch(() => alert(errorMessage))
            .finally(() => setLoading(false))
    }

    return (
        <MainLayout title={`${platformId}`}>
            <MaterialTable
                actions={[
                    rowData => ({
                        icon: () => <DashboardIcon />,
                        tooltip: 'Acessar painel do cliente',
                        onClick: () => goToStorePanel(rowData.userId)
                    }),
                    rowData => ({
                        icon: () => <StorefrontIcon />,
                        tooltip: 'Acessar loja do cliente',
                        onClick: () => window.open(rowData.storeUrl)
                    }),
                    rowData => {
                        const androidDownloadUrl = _.get(rowData, 'platforms.android.downloadUrl', '');

                        return {
                            icon: () => <AndroidIcon />,
                            tooltip: 'Link do aplicativo Android',
                            onClick: () => window.open(androidDownloadUrl),
                            disabled: _.isEmpty(androidDownloadUrl)
                        }
                    },
                    rowData => {
                        const iOSDownloadUrl = _.get(rowData, 'platforms.ios.downloadUrl', '');

                        return {
                            icon: () => <AppleIcon />,
                            tooltip: 'Link do aplicativo iOS',
                            onClick: () => window.open(iOSDownloadUrl),
                            disabled: _.isEmpty(iOSDownloadUrl)
                        }
                    }
                ]}
                columns={[
                    {
                        title: 'ID',
                        field: 'id',
                        defaultFilter: defaultFilter ? defaultFilter : false,
                        customSort: (a, b) => {
                            return parseInt(a['id']) > parseInt(b['id']) ? 1 : -1
                        }
                    },
                    {
                        title: 'Nome',
                        field: nameFieldName,
                        customSort: (a, b) => a[nameFieldName].toLowerCase().localeCompare(b[nameFieldName].toLowerCase())
                    },
                    { title: 'Email', field: 'email' },
                    {
                        defaultFilter: ['on_trial', 'active'],
                        title: 'Status da Loja',
                        field: 'status',
                        lookup: {
                            on_trial: 'Período de testes',
                            trial_ended: 'Teste finalizado',
                            active: 'Ativo',
                            suspended: 'Suspenso',
                            archived: 'Arquivado'
                        },
                        render: rowData => getStoreStatusChip(_.get(rowData, 'status', '')),
                    },
                    {
                        title: 'Data de Cadastro',
                        field: createdAtFieldName,
                        render: rowData => formatFirestoreTimestamp(rowData[createdAtFieldName], 'DD/MM/YYYY'),
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(a[createdAtFieldName]);
                            let date2 = mapFirestoreTimestampToMoment(b[createdAtFieldName]);

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Status Android',
                        field: 'platforms.android.publishStatus',
                        render: rowData => getPublishStatusChip(_.get(rowData, 'platforms.android.publishStatus', '')),
                        lookup: {
                            first_publish_pending: 'Pendente',
                            pending_listing_creation: 'Aguardando criação na loja',
                            developer_account_pending: 'Aguardando conta de desenvolvedor',
                            pending_google_play_developer_account_verification: 'Aguardando verificação da conta de desenvolvedor Google Play',
                            publishing: 'Aguardando publicação',
                            in_review: 'Em análise',
                            published: 'Publicado',
                            pending_update: 'Atualização pendente'
                        },
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(_.get(a, 'platforms.android.publishStatusUpdatedAt', moment()));
                            let date2 = mapFirestoreTimestampToMoment(_.get(b, 'platforms.android.publishStatusUpdatedAt', moment()));

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        }
                    },
                    {
                        title: 'Status iOS',
                        field: 'platforms.ios.publishStatus',
                        render: rowData => getPublishStatusChip(_.get(rowData, 'platforms.ios.publishStatus', '')),
                        lookup: {
                            first_publish_pending: 'Pendente',
                            pending_listing_creation: 'Aguardando criação na loja',
                            developer_account_pending: 'Aguardando conta de desenvolvedor',
                            publishing: 'Aguardando publicação',
                            pending_apple_account_renewal: 'Aguardando renovação da conta Apple',
                            pending_apple_account_terms_agreement: 'Aguardando aceite de novos termos',
                            in_review: 'Em análise',
                            published: 'Publicado',
                            pending_update: 'Atualização pendente'
                        },
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(_.get(a, 'platforms.ios.publishStatusUpdatedAt', moment()));
                            let date2 = mapFirestoreTimestampToMoment(_.get(b, 'platforms.ios.publishStatusUpdatedAt', moment()));

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        }
                    },
                ]}
                data={stores}
                detailPanel={rowData => <DetailsPanel onDataUpdate={getData} platformId={platformId} store={rowData} />}
                icons={icons}
                isLoading={loading}
                localization={localization}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                        let storesArray = [];

                        data.forEach(store => {
                            storesArray.push({
                                id: store.id,
                                name: store[nameFieldName],
                                createdAt: formatFirestoreTimestamp(store[createdAtFieldName], 'DD/MM/YYYY'),
                                email: store.email,
                                subscriptionPlanId: store.subscriptionPlanId,
                                planPrice: plans[store.subscriptionPlanId]?.totalPrice
                            })
                        });

                        const header = 'ID;Nome da Loja;Data de Cadastro;Email;Plano;Valor Plano;';
                        convertArrayToCsv(storesArray, header);
                    },
                    filtering: true,
                    pageSize: 10
                }}
                title={''}
            />
        </MainLayout>
    );
}

export default withRouter(withFirebase(StoresPage));