import React from 'react';
import ReactModal from 'react-modal';
import ReactJson from 'react-json-view'

ReactModal.setAppElement('#root')

const customStyles = {
    overlay : {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: '500px',
        width: '60%',
        margin: 'auto'
    }
};

export default function JsonModal({ data, isOpen, onClose }) {
    let dataToDisplay = JSON.parse(JSON.stringify(data))

    // tableData is a property added by Material Table that is not related to our data
    if(!_.isNil(dataToDisplay.tableData)) {
        delete dataToDisplay.tableData;
    }

    return  (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <ReactJson 
                displayDataTypes={false}
                enableClipboard={false}
                name={null}
                sortKeys={true}
                src={dataToDisplay}
            />
        </ReactModal>
    )
}