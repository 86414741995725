import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import { getStoreStatusChip } from '../_helpers/mappers';
import _ from 'lodash';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';
import { withFirebase } from '../_firebase';
import firebaseRoot from 'firebase/app';
import 'firebase/firestore';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: 'fit-content',
        width: '35%',
        margin: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    fullWidthShrink: {
        width: '133%'
    },
    statusTooltipContainer: {
        marginBottom: 15,
        marginTop: 5
    }
}))

function ChangeStoreStatusDialog({ data, firebase, isOpen, onClose, onDataUpdate, platformId }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        churnedAt: '',
        currentStatus: '',
        status: '',
        trialEndsAt: ''
    })

    const [errors, setErrors] = useState({
        status: null,
        trialEndsAt: null
    })

    useEffect(() => {
        setFormData({
            ...formData,
            currentStatus: _.get(data, 'status', '')
        })
    }, [data]);

    const handleChange = (e) => {
        let newFormData = { ...formData };
        newFormData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData(newFormData);
    }

    const handleSave = () => {
        setLoading(true);

        const errors = {};
        setErrors({});

        const requiredMsg = "Este campo é obrigatório.";

        if (_.isEmpty(formData.status)) {
            errors['status'] = requiredMsg;
        }

        if (formData.status === 'on_trial' && _.isEmpty(formData.trialEndsAt)) {
            errors['trialEndsAt'] = requiredMsg;
        }

        if (_.isEmpty(errors)) {
            if (formData.status !== 'on_trial') {
                delete formData['trialEndsAt'];
            } else {
                formData['trialEndsAt'] = new firebaseRoot.firestore.Timestamp.fromDate(new Date(formData.trialEndsAt))
            }

            if (formData.status !== 'archived' || _.isEmpty(formData.churnedAt)) {
                delete formData['churnedAt'];
            } else {
                formData['churnedAt'] = new firebaseRoot.firestore.Timestamp.fromDate(new Date(formData.churnedAt))
            }

            delete formData['currentStatus'];

            firebase.updateStore(platformId, data.id, formData)
                .then(() => {
                    onDataUpdate();
                    onClose();
                })
                .catch((e) => {
                    console.log(e)
                    alert(`Não foi possível alterar o status da loja.`)
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false);
            setErrors(errors);
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <h2 align="center" className={classes.title}>Alterar status da loja</h2>

            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink>Status atual</InputLabel>
                    <div className={classes.statusTooltipContainer}>
                        {getStoreStatusChip(formData.currentStatus)}
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <FormControl className={classes.formControl} error={!!errors.status} fullWidth>
                        <InputLabel htmlFor="status" shrink>Novo status</InputLabel>
                        <Select
                            native
                            value={formData.status}
                            onChange={handleChange}
                            inputProps={{
                                name: 'status',
                                id: 'status',
                            }}
                        >
                            <option value="">Selecione o novo status...</option>
                            <option value={'on_trial'}>Período de testes</option>
                            <option value={'trial_ended'}>Teste finalizado</option>
                            <option value={'active'}>Ativo</option>
                            <option value={'suspended'}>Suspenso</option>
                            <option value={'archived'}>Arquivado</option>
                        </Select>
                        {errors.status && <FormHelperText>{errors.status}</FormHelperText>}
                    </FormControl>
                </Grid>

                {formData.status === 'on_trial' &&
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} error={!!errors.trialEndsAt} fullWidth>
                            <TextField
                                error={!!errors.trialEndsAt}
                                label="Data de término do período de testes"
                                name="trialEndsAt"
                                onChange={handleChange}
                                type="date"
                                value={formData.trialEndsAt || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {errors.trialEndsAt && <FormHelperText>{errors.trialEndsAt}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }

                {formData.status === 'archived' &&
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl} error={!!errors.churnedAt} fullWidth>
                            <TextField
                                error={!!errors.churnedAt}
                                label="Data de saída"
                                name="churnedAt"
                                onChange={handleChange}
                                type="date"
                                value={formData.churnedAt || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {errors.churnedAt && <FormHelperText>{errors.churnedAt}</FormHelperText>}
                        </FormControl>
                    </Grid>
                }

                <Grid container justify="center" item xs={12}>
                    {loading ? <CircularProgress /> :
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Salvar
                        </Button>
                    }
                </Grid>
            </Grid>
        </ReactModal>
    )
}

export default withFirebase(ChangeStoreStatusDialog);