import axios from "axios";
import config from "../../_helpers/config";

export const mobilizeiUsersService = {
    signIn
};

function signIn() {
  return axios
    .post(
        `${config.MOBILIZEI_API_BASE_URL}/users/signin`,
        { 
            email: window.localStorage.getItem('email'),
            password: window.localStorage.getItem('password')
        }
    )
    .then(response => {
      return response.data.token;
    })
}