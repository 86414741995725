import React, { useEffect, useState } from 'react';
import MainLayout from '../_layouts/main';
import { withFirebase } from '../_firebase';
import { withRouter } from 'react-router-dom';
import MaterialTable from 'material-table';
import { icons, localization } from '../_helpers/materialTableProperties';
import { convertArrayToCsv } from '../_helpers/csvConverter';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatFirestoreTimestamp, mapFirestoreTimestampToMoment } from '../_helpers/mappers';
import moment from 'moment';

function LeadsPage({ firebase }) {
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setLoading(true);

        firebase.getLeads()
            .then(leads => {
                setLeads(leads);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            })
    }

    const deleteLead = (leadId) => {
        const message = "Você tem certeza que deseja excluir este lead?";

        if(confirm(message)) {
            setLoading(true);

            firebase.deleteLead(leadId)
                .then(() => getData())
                .finally(() => setLoading(false))
        }
    }

    const getStoreUrlLink = (storeUrl) => {
        if(!_.isEmpty(storeUrl)) {
            return <a target="_blank" href={storeUrl.includes('http') ? storeUrl : `//${storeUrl}` }>{storeUrl}</a>
        }

        return '-';
    }

    const getWhatsAppLink = (phone) => {
        if(!_.isEmpty(phone)) {
            const nomalizedPhone = phone.replace(/\D/g,'');
            return <a target="_blank" href={`https://api.whatsapp.com/send?phone=55${nomalizedPhone}`}>{phone}</a>
        }

        return '-';
    }

    return (
        <MainLayout title={`Leads`}>
            <MaterialTable
                actions={[
                    rowData => ({
                        icon: () => <DeleteIcon />,
                        tooltip: 'Excluir lead',
                        onClick: () => deleteLead(rowData.id)
                    })
                ]}
                columns={[
                    {
                        title: 'Data de Cadastro',
                        field: 'createdAt',
                        render: rowData => formatFirestoreTimestamp(rowData['createdAt'], 'DD/MM/YYYY'),
                        customSort: (a, b) => {
                            let date1 = mapFirestoreTimestampToMoment(a['createdAt']);
                            let date2 = mapFirestoreTimestampToMoment(b['createdAt']);

                            if (!date1) date1 = moment();
                            if (!date2) date2 = moment();

                            return date1.diff(date2)
                        },
                        defaultSort: 'desc'
                    },
                    {
                        title: 'Nome',
                        field: 'name',
                        customSort: (a, b) => a['name'].toLowerCase().localeCompare(b['name'].toLowerCase())
                    },
                    { 
                        title: 'Email', 
                        field: 'email' 
                    },
                    { 
                        title: 'Telefone', 
                        field: 'phone',
                        render: rowData => getWhatsAppLink(rowData.phone)
                    },
                    {
                        title: 'Plataforma',
                        field: 'platformId',
                        render: rowData => {
                            const platformId = rowData['platformId'];

                            if(platformId === 'nuvemshop') {
                                return 'Nuvemshop';
                            }
                            if(platformId === 'tray') {
                                return 'Tray';
                            }
                            if(platformId === 'outra') {
                                if(_.isEmpty(rowData['platformName'])) {
                                    return 'Outra'
                                } else {
                                    return `Outra - ${rowData['platformName']}`;
                                }
                            }
                        }
                    },
                    { 
                        title: 'Url da loja', 
                        field: 'storeUrl',
                        render: rowData => getStoreUrlLink(rowData.storeUrl)
                    }
                ]}
                data={leads}
                icons={icons}
                isLoading={loading}
                localization={localization}
                options={{
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    exportCsv: (columns, data) => {
                        let leadsArray = [];

                        data.forEach(lead => {
                            leadsArray.push({
                                createdAt: formatFirestoreTimestamp(lead['createdAt'], 'DD/MM/YYYY'),
                                name: lead.name,
                                email: lead.email,
                                phone: lead.phone,
                                platformId: lead.platformId,
                                platformName: lead.platformName,
                                storeUrl: lead.storeUrl
                            })
                        });

                        const header = 'Data de Cadastro;Nome;E-mail;Telefone;Plataforma;Nome da Plataforma;Url da Loja';
                        convertArrayToCsv(leadsArray, header);
                    },
                    filtering: true,
                    pageSize: 10
                }}
                title={''}
            />
        </MainLayout>
    );
}

export default withRouter(withFirebase(LeadsPage));