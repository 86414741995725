import config from "../../_helpers/config";
import { mobilizeiUsersService } from "./users.service";

export const apiFetcher = (path, init, attempt = 1) => {
  const at = window.localStorage.getItem('at');
  let options = init;

  let url = `${config.MOBILIZEI_API_BASE_URL}/${path}`;

  let headers = new Headers();
  headers.append('Authorization', `Bearer ${at}`);
  headers.append('Content-Type', 'application/json');
  options['headers'] = headers;

  return fetch(url, init).then(response => {
    if (response.status === 204) {
      return;
    }

    if (response.status === 401 && attempt <= 3) {
      // handle 401 error
      return mobilizeiUsersService.signIn()
        .then(at => {
          window.localStorage.setItem('at', at);
          return apiFetcher(path, init, attempt + 1);
        })
        .catch(() => {
          return;
        });
    }

    return response.json().then(data => {
      if (!response.ok) {

        const error = data || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  });
};