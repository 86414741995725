export const planPeriodicity = {
    MONTHLY: "monthly",
    YEARLY: "yearly"
}

export const plans = {
    plan_initial: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_initial_annual: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 1069,
        periodicity: planPeriodicity.YEARLY,
    },
    plan_monthly_2022: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_annual_2022: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 1068,
        periodicity: planPeriodicity.YEARLY,
    },
    plan_monthly_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 149,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_annual_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 1548,
        periodicity: planPeriodicity.YEARLY
    },
    plano_basico_mensal_2023: {
        name: "Básico",
        isBasic: true,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_basico_anual_2023: {
        name: "Básico",
        isBasic: true,
        price: 89,
        totalPrice: 1068,
        periodicity: planPeriodicity.YEARLY
    },
    plano_pro_mensal_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 149,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_anual_2023: {
        name: "Pro",
        isBasic: false,
        price: 129,
        totalPrice: 1548,
        periodicity: planPeriodicity.YEARLY
    },
    plano_basico_mensal_2024: {
        name: "Básico",
        isBasic: true,
        price: 129,
        totalPrice: 129,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_basico_anual_2024: {
        name: "Básico",
        isBasic: true,
        price: 109,
        totalPrice: 1308,
        periodicity: planPeriodicity.YEARLY
    },
    plano_pro_mensal_2024: {
        name: "Pro",
        isBasic: false,
        price: 199,
        totalPrice: 199,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_anual_2024: {
        name: "Pro",
        isBasic: false,
        price: 169,
        totalPrice: 2028,
        periodicity: planPeriodicity.YEARLY
    },
    plano_exclusivo_mensal_2023: {
        name: "Exclusivo",
        isBasic: false,
        price: 599,
        totalPrice: 599,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_smart_push_2023: {
        name: "Pro + Push Inteligente",
        isBasic: false,
        price: 299,
        totalPrice: 299,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_smart_push_tray_2023: {
        name: "Pro + Push Inteligente",
        isBasic: false,
        price: 200,
        totalPrice: 200,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_mensal_valor_200: {
        name: "Exclusivo",
        isBasic: false,
        price: 200,
        totalPrice: 200,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_mensal_valor_300: {
        name: "Exclusivo",
        isBasic: false,
        price: 300,
        totalPrice: 300,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_299_mensal_2024: {
        name: "Exclusivo",
        isBasic: false,
        price: 299,
        totalPrice: 299,
        periodicity: planPeriodicity.MONTHLY
    }
}