import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactModal from 'react-modal';
import { Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, Radio, RadioGroup, Select, Switch, TextField, Tooltip } from '@material-ui/core';
import { getPublishStatusChip } from '../_helpers/mappers';
import _ from 'lodash';
import HelpIcon from '@material-ui/icons/Help';
import { mobilizeiStoresService } from '../_services/mobilizei/stores.service';

ReactModal.setAppElement('#root')

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(10, 10, 10, 0.45)',
        zIndex: 999
    },
    content: {
        height: 'fit-content',
        width: '35%',
        margin: 'auto'
    }
};

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    fullWidthShrink: {
        width: '133%'
    },
    statusTooltipContainer: {
        marginBottom: 15,
        marginTop: 5
    }
}))

export default function SetPublishStatusModal({ data, isOpen, onClose, onDataUpdate, platformId }) {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        checkUpdate: false,
        currentBuildVersion: null,
        currentStatus: '',
        downloadUrl: '',
        platform: '',
        publishStatus: ''
    })

    const [errors, setErrors] = useState({
        currentBuildVersion: null,
        downloadUrl: null,
        publishStatus: null
    })

    const handleMobilePlatformSelect = (e) => {
        const mobilePlatform = e.target.value;
        const platformData = _.get(data, `platforms.${mobilePlatform}`, {});

        setFormData({
            checkUpdate: _.get(data, 'generalSettings.checkUpdate', false),
            currentBuildVersion: platformData.currentBuildVersion,
            currentStatus: platformData.publishStatus,
            downloadUrl: platformData.downloadUrl,
            platform: mobilePlatform,
            publishStatus: ''
        });
    }

    const handleChange = (e) => {
        let newFormData = { ...formData };
        newFormData[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData(newFormData);
    }

    const handleSave = () => {
        setLoading(true);

        const errors = {};
        setErrors({});

        const requiredMsg = "Este campo é obrigatório.";

        if (formData.publishStatus === 'published') {
            if (formData.checkUpdate && _.isEmpty(formData.currentBuildVersion)) {
                errors['currentBuildVersion'] = requiredMsg;
            }
            if (_.isEmpty(formData.downloadUrl)) {
                errors['downloadUrl'] = requiredMsg;
            }
        }

        if (_.isEmpty(formData.publishStatus)) {
            errors['publishStatus'] = requiredMsg;
        }

        if (_.isEmpty(errors)) {
            mobilizeiStoresService.setPublishStatus(platformId, data.id, formData)
                .then(() => {
                    onDataUpdate();
                    onClose();
                })
                .catch(() => {
                    alert('Não foi possível atualizar o status de publicação do aplicativo.')
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(false);
            setErrors(errors);
        }
    }

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onClose()}
            style={customStyles}
        >
            <h2 align="center" className={classes.title}>Alterar status de publicação</h2>

            <Tooltip className={classes.helpButton} title="Em dúvida sobre o processo de publicação de um aplicativo? Clique aqui para visualizar suas etapas.">
                <IconButton className={classes.iconButton} onClick={() => window.open(require('../_assets/img/publish_process.png').default, '_blank')}>
                    <HelpIcon className={classes.icon} />
                </IconButton>
            </Tooltip>

            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                    <InputLabel htmlFor="mobilePlatform" shrink>Plataforma</InputLabel>
                    <RadioGroup aria-label="mobilePlatform" name="mobilePlatform" row value={formData.platform} onChange={handleMobilePlatformSelect}>
                        <FormControlLabel value="android" control={<Radio />} label="Android" />
                        <FormControlLabel value="ios" control={<Radio />} label="iOS" />
                    </RadioGroup>
                </Grid>

                {!_.isEmpty(formData.platform) &&
                    <>
                        <Grid item xs={12}>
                            <InputLabel shrink>Status atual</InputLabel>
                            <div className={classes.statusTooltipContainer}>
                                {getPublishStatusChip(formData.currentStatus)}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} error={!!errors.publishStatus} fullWidth>
                                <InputLabel htmlFor="publishStatus" shrink>Novo status</InputLabel>
                                <Select
                                    native
                                    value={formData.publishStatus}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'publishStatus',
                                        id: 'publishStatus',
                                    }}
                                >
                                    <option value="">Selecione o novo status...</option>
                                    <option value={'first_publish_pending'}>Pendente</option>
                                    <option value={'pending_listing_creation'}>Aguardando criação na loja</option>
                                    <option value={'developer_account_pending'}>Aguardando conta de desenvolvedor</option>
                                    <option value={'publishing'}>Aguardando publicação</option>
                                    {formData.platform === 'android' &&
                                        <option value={'pending_google_play_developer_account_verification'}>Aguardando verificação da conta de desenvolvedor Google Play</option>
                                    }
                                    {formData.platform === 'ios' &&
                                        <>
                                            <option value={'pending_apple_account_renewal'}>Aguardando renovação da conta Apple</option>
                                            <option value={'pending_apple_account_terms_agreement'}>Aguardando aceite de novos termos</option>
                                        </>
                                    }
                                    <option value={'in_review'}>Em análise</option>
                                    <option value={'published'}>Publicado</option>
                                    <option value={'pending_update'}>Atualização pendente</option>
                                </Select>
                                {errors.publishStatus && <FormHelperText>{errors.publishStatus}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {formData.publishStatus === 'published' &&
                            <>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} error={!!errors.downloadUrl} fullWidth>
                                        <TextField
                                            error={!!errors.downloadUrl}
                                            fullWidth
                                            label="Url de download do aplicativo"
                                            margin="normal"
                                            name="downloadUrl"
                                            onChange={handleChange}
                                            placeholder={
                                                formData.platform === 'android' ?
                                                    "Ex.: https://play.google.com/store/apps/details?id=br.com.mobilizei.meuapp" :
                                                    "https://apps.apple.com/br/app/meuapp/id1869968957"
                                            }
                                            value={formData.downloadUrl || ''}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors.downloadUrl && <FormHelperText>{errors.downloadUrl}</FormHelperText>}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <InputLabel htmlFor="checkUpdate" shrink className={classes.fullWidthShrink}>
                                        Exibir mensagem de "atualização disponível" em versões passadas do aplicativo?
                                    </InputLabel>
                                    <FormControlLabel
                                        control={<Switch checked={formData.checkUpdate} onChange={handleChange} name="checkUpdate" />}
                                        label={formData.checkUpdate ? 'Exibir a mensagem' : 'Não exibir a mensagem'}
                                    />
                                </Grid>

                                {formData.checkUpdate &&
                                    <Grid item xs={12}>
                                        <FormControl className={classes.formControl} error={!!errors.currentBuildVersion} fullWidth>
                                            <TextField
                                                error={!!errors.currentBuildVersion}
                                                label="Versão do build"
                                                name="currentBuildVersion"
                                                onChange={handleChange}
                                                placeholder="Ex.: 302"
                                                type="number"
                                                value={formData.currentBuildVersion || ''}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors.currentBuildVersion && <FormHelperText>{errors.currentBuildVersion}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                }
                            </>
                        }

                        <Grid container justify="center" item xs={12}>
                            {loading ? <CircularProgress /> :
                                <Button variant="contained" color="primary" onClick={handleSave}>
                                    Salvar
                                </Button>
                            }
                        </Grid>
                    </>
                }
            </Grid>
        </ReactModal>
    )
}